import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { useCompanyApi } from '../../_common/hooks/api/CompanyApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { URLS } from '../../_config';

interface IncomingProps {
}

const InvestmentArea = ({ }: IncomingProps) => {

    const { showLoader, hideLoader } = useAppLoader();
    const companyApi = useCompanyApi();
    const navigate = useNavigate()

    const [companyList, setCompanyList] = useState<any>([])

    const getCompanyList = () => {
        showLoader();
        companyApi.getCompanyList({}, (message: string, resp: any) => {
            // console.log({ resp });
            hideLoader();
            setCompanyList(resp?.data?.companyList);
        }, (message: string) => {
            toast.error(message)
            hideLoader();
        });
    }

    useEffect(() => {
        getCompanyList();
    }, []);

    return (
        <React.Fragment>
            <div className="target-box h-380 text-adj">
                <h2>Our Targeted
                    <span>investment area</span></h2>
                <ul>
                    {
                        companyList && companyList.length > 0 ?
                            companyList.map((company: any, index: number) => (
                                <li key={index} onClick={() => navigate(`/company/${company.id}`)} style={{cursor: "pointer"}}>
                                    <div><img src={company?.avatar?.thumb} alt="#" /></div>
                                    <span>{company?.name}</span>
                                </li>
                            ))
                            : null
                    }
                </ul>
                <div className="portfolio-btn">
                    <Link to={URLS.ALL_COMPANIES} className="btn btn2">View all companies</Link>
                </div>
            </div>
        </React.Fragment>
    )
}

export default InvestmentArea
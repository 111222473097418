// css ----------
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { URLS } from './_config';
import PrivateRoutes from './_config/privateRoutes';
import OpenRoutes from './_config/openRoutes';
import Login from './pages/login';
import SignUp from './pages/signup';
import Dashboard from './pages/dashboard';
import Benefits from './pages/benefits';
import Report from './pages/report';
import RegistrationSuccessful from './pages/registrationSuccessful';
import Profile from './pages/profile';
import AllCompanies from './pages/allCompanies';
import ContactUs from './pages/contactUs';
import ProfileUpdate from "./pages/profileUpdate";
import BuyCertificate from "./pages/buyCertificate";
import CompanyDetails from "./pages/companyDetails";
import OurgroupCompanyDetails from "./pages/ourgroupCompanyDetails";
import InvestmentProfile from "./pages/investmentProfile";
import ForgotPassword from "./pages/forgotPassword";
import ResetPassword from "./pages/resetPassword";


function App() {
	return (
		<Router>
			<Routes>
				<Route element={<OpenRoutes />} >
					<Route path={URLS.LOGIN} element={<Login />} />
					<Route path={URLS.SIGNUP} element={<SignUp />} />
					<Route path={URLS.REGISTRATION_SUCESSFUL} element={<RegistrationSuccessful />} />
					<Route path={URLS.FORGOT_PASSWORD} element={<ForgotPassword />} />
					<Route path={URLS.RESET_PASSWORD} element={<ResetPassword />} />
					{/* bottom route will be in Private route */}
				</Route>

				{/* <Route path={URLS.SUCCESS_PAGE} element={<SuccessPage />} /> */}
				<Route element={<PrivateRoutes />} >
					<Route path={URLS.HOME} element={<Dashboard />} />
					<Route path={URLS.REPORT} element={<Report />} />
					<Route path={URLS.BUY_CERTIFICATE} element={<BuyCertificate />} />
					<Route path={URLS.BENIFITS} element={<Benefits />} />
					<Route path={URLS.PROFILE} element={<Profile />} />
					<Route path={URLS.ALL_COMPANIES} element={<AllCompanies />} />
					<Route path={URLS.CONTACT_US} element={<ContactUs />} />
					<Route path={URLS.PROFILE_UPDATE} element={<ProfileUpdate />} />
					<Route path={URLS.COMPANY_DETAILS} element={<CompanyDetails />} />
					<Route path={URLS.OURGROUP_COMPANY_DETAILS} element={<OurgroupCompanyDetails />} />
					<Route path={URLS.INVESTMENT_PROFILE} element={<InvestmentProfile />} />
				</Route>
			</Routes>
		</Router>
	);
}

export default App;
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { changeString, extractContent } from '../../_common/functions'
import { useCompanyApi } from '../../_common/hooks/api/CompanyApiHook'
import { URLS } from '../../_config'

const CompanyPortfolio = () => {

    const companyApi = useCompanyApi()

    const [details, setDetails] = useState<any>(null)
    const [toExpand, setToExpand] = useState<boolean>(false)

    const getOurgroupCompanyDetails = () => {
        companyApi.getOurgroupCompanyDetails({
            financial_year: "2022-2023"
        },
            (message: string, resp: any) => {
                // console.log({ resp })
                setDetails(resp.data)
            }, (message: string) => { })
    }

    useEffect(() => {
        getOurgroupCompanyDetails()
    }, [])

    return (
        <div className="yellow-box h-380">
            <div className="yellow-box-text text-adj">
                <div className='d-flex justify-content-between w-100 mb-2'>
                    <h1><span>Our Group</span>Company Portfolio</h1>
                    <img src="./assets/img/growth.svg" alt="#" />
                </div>
                {/* <p style={{ minWidth: "400px" }}>{changeString(extractContent(details?.company?.description), 222)} ...
                </p> */}
                <div style={{ maxHeight: "172px", overflow: "auto" }} className="custom-scroll px-3">
                    {!toExpand ?
                        <p>{changeString(extractContent(details?.company?.description), 222)}
                            {details && details?.company?.description.length > 222 ?
                                <span onClick={() => setToExpand(!toExpand)} style={{ cursor: "pointer" }}>see more...</span>
                                : null
                            }
                        </p>
                        :
                        <p >{extractContent(details?.company?.description)} <span onClick={() => setToExpand(!toExpand)} style={{ cursor: "pointer" }}>see less...</span></p>
                    }
                </div>
                {/* :
                    <div style={{ maxHeight: "360px", overflow: "auto" }} className="custom-scroll">
                         <p >{extractContent(details?.company?.description)} <span onClick={() => setToExpand(!toExpand)} style={{ cursor: "pointer" }}>see less...</span></p>
                    </div> */}

                <Link to={URLS.OURGROUP_COMPANY_DETAILS} className="btn">Check Investment Performances</Link>
            </div>
            {/* <div className="yellow-box-img"><img src="./assets/img/growth.svg" alt="#" /></div> */}
        </div>
    )
}

export default CompanyPortfolio
import React from "react";
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import '../styles/welcome.scss';
import { toast } from "react-hot-toast";
import Header from "../components/header";
import LeftPanel from "../components/leftPanel";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { Link } from "react-router-dom";

export default function ContactUs() {

	const { register, handleSubmit, formState: { errors }, reset } = useForm();
	const { showLoader, hideLoader } = useAppLoader();
	const commonApi = useCommonApi();

	const onSubmit = (data: any) => {
		// console.log({ data })
		showLoader();
		commonApi.contactUs(data, (message: string, resp: any) => {
			console.log({ resp })
			hideLoader();
			reset();
			toast.success(message)
		}, (message: string) => {
			hideLoader();
			reset();
			toast.error(message)
		});
	}

	return (
		<React.Fragment>
			<div className="circle-bg"></div>
			<Header />
			<LeftPanel />
			<section className="main-body">
				<div className="right-menu-part">
					<div className="dash-body">
						<div className="container-fluid">
							{/* <h1 className="logo-wrap"><img src="./assets/img/logo.png" alt="" /></h1> */}
							<div className="welcome-text">
								<h2><span>Contact Us</span></h2>
							</div>
							<div className="login-box">
								<form onSubmit={handleSubmit(onSubmit)}>
									<div className="mb-3">
										<label htmlFor="" className="form-label">Subject</label>
										<input type="text" className="form-control" placeholder="Enter Subject" {...register("title", { required: true })} />
										{errors?.title ? <div className="invalid-feedback">
											{errors.title.type === "required" ? 'Required' : null}
										</div> : null}
									</div>
									<div className="mb-3">
										<label htmlFor="" className="form-label">Message</label>
										<textarea rows={10} className="form-control" placeholder="Enter Message" {...register("message", { required: true })} />
										{errors?.message ? <div className="invalid-feedback">
											{errors.message.type === "required" ? 'Required' : null}
										</div> : null}
									</div>
									<div className="mb-3">
										<Button className="btn btn-primary me-3" type="submit">Send</Button>
										<Link to="/"><button className="btn btn-outline-secondary" type="button">Back</button></Link>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</React.Fragment>
	);
}
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom'
import { formatNumber } from '../../_common/functions';
import { usePaymentApi } from '../../_common/hooks/api/PaymentApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { useUserSelector } from '../../_common/hooks/selectors/userSelector';
import { URLS } from '../../_config';
import SellCertificateModal from '../modals/sellCertificate.modal';

interface IncomingProps {
}

const PortfolioValue = ({ }: IncomingProps) => {

    const { showLoader, hideLoader } = useAppLoader();
    const paymentApi = usePaymentApi();
    const user: any = useUserSelector();

    const [alltransactions, setTransactions] = useState<any>(null)
    const [currentPortfolioValue, setCurrentPortfolioValue] = useState<number>(0)
    const [showSellCertificateModal, setShowSellCertificateModal] = useState<boolean>(false);;

    const closeCreateCase = () => {
        setShowSellCertificateModal(false)
    }

    const getTransaction = () => {
        showLoader();
        paymentApi.getTransaction({}, (message: string, resp: any) => {
            // console.log({resp});
            hideLoader();
            setTransactions(resp?.data);
            let dasboadprice = (parseFloat(resp?.data?.current_certificate_value) * parseInt(resp?.data?.number_of_certifcate)) + parseFloat(user?.portfolio_interest) + parseFloat(user?.profit_balance);
            setCurrentPortfolioValue(dasboadprice);

        }, (message: string) => {
            toast.error(message)
            hideLoader();
        });
    }

    const handleSellCertificate = () => {
        if (alltransactions?.number_of_unlock_certificate < 1) return toast.error('You have no certificate matured yet to sell')
        setShowSellCertificateModal(true)
    }

    useEffect(() => {
        getTransaction();
    }, []);

    return (
        <React.Fragment>
            <SellCertificateModal
                show={showSellCertificateModal}
                close={closeCreateCase}
                maxQty={alltransactions?.number_of_unlock_certificate}
            />
            <div className="portfolio-sec h-380">
                <h3>Your Portfolio Value</h3>
                {/* <h2>R {currentPortfolioValue.toLocaleString()}</h2> */}
                <h2>R {formatNumber(currentPortfolioValue)}</h2>
                <div className="dividend-sec">
                    <p>Last Profit on Portfolio Received On <span>{moment(alltransactions?.last_updated_date).format("DD/MM/YYYY")}</span> </p>
                </div>
                <h5>Total Certificate - {alltransactions?.number_of_certifcate}</h5>
                <h5>Total Matured Certificate - {alltransactions?.number_of_unlock_certificate}</h5>
                <div className="portfolio-btn">
                    <button type="button" onClick={() => handleSellCertificate()} className="btn me-1">Sell Certificate</button>
                    <Link to={URLS.BUY_CERTIFICATE}>  <button type="button" className="btn ms-1">Buy Certificate</button> </Link>
                </div>
            </div>
        </React.Fragment>
    )
}

export default PortfolioValue